<template>
    <div class='meetlist'>
      <ItemScroll ref="ItemScroll">
        <div>
          <h4 class='meettitle'>
            待召开会议({{meetInfo.ready_count}}) 
            <!-- <el-button  size='small' @click.native='openBook'>专家操作手册</el-button> -->
          </h4>
          <div class='meetlist_block meetlist_block_margin'>
            <div class='contcontent' v-for="(item,index) in getMeetList([0,1])" :key='index'  @click='inMeetDetail(item)' >
                <div class='meetrow meetrow-courseware-block'>
                    <span>会议讲题</span>
                    <div class="meetrow-courseware">
                      <custom-button v-if="!item.courseware_id"  @click.stop="selectCourseware(item)" round>待选题</custom-button>
                      <span v-if="item.courseware_id">{{item.show_title}}</span>
                      <custom-button v-if="item.courseware_id"  @click.stop="previewCourseware(item)" round>预览课件</custom-button>
                    </div>
                </div>
                <div class='meetrow'>
                    <span>会议日期</span>
                    <span>{{item.start_time}}</span>
                </div>
                <!-- <div class='meetrow'>
                    <span>会议主题</span>
                    <span>{{item.meeting_name}}</span>
                </div> -->
                <div class='meetrow'>
                    <span>会议医院</span>
                    <span>{{item.hospital}}</span>
                </div>
                <div class='meetrow' meetrowend>
                    <span>主持专家</span>
                    <span>{{item.host_name}} 教授</span>
                </div>
                <div class='meetrow'>
                    <span>申请人</span>
                    <span>{{item.admin_phone}}</span>
                    <div v-if='item.meeting_status != 2 && item.courseware_id'  class='meetrow meetoperashare'>
                        <span class='meetinmeet global_primary_text_color global_primary_border_color' @click.stop='inMeet(item)'>
                            <span>进入会议</span>
                            <!-- <i class='el-icon-arrow-right'></i> -->
                        </span>
                    </div>
                </div>
                <div class='meetrighticon'>
                    <i class='el-icon-arrow-right'></i>
                </div>
            </div>
          </div>
          <h4 class='meettitle'>已召开会议({{meetInfo.finish_count}})</h4>
          <div class='meetlist_block' >
            <div class='meetstarttable' >
              <div class='meettablethead'>
                  <div class='meettwo'>会议日期</div>
                  <div class='meettwo'>会议医院</div>
                  <div class='meetone'>主持专家</div>
                  <div class='meetone'>申请人</div>
              </div>
              <div class='meettabletbody' v-for="(item,index) in getMeetList([2])" :key='index' >
                  <div class='meettabletr'>
                      <div class='meettwo'>
                          {{getMeetDate(item.start_time)}}
                      </div>
                      <div class='meettwo'>{{item.meeting_name}}</div>
                      <div class='meetone'>{{item.hospital}}</div>
                      <div class='meetone'>**{{item.admin_phone.substr(-4)}}</div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </ItemScroll>
</div>
</template>
<script>
import ItemScroll from '@/components/unit/ItemScroll'
import CustomButton from '@/components/unit/CustomButton'
export default {
    name:'meetlist',
    data:()=>{
        return {
          data:[],
          search:{
            pagesize:9999
          },
          meetInfo:{
            ready_count:0,
            finish_count:0
          }
        }
    },
    created(){
        document.getElementsByTagName('title')[0].innerHTML = '我的互动课堂',
        this.loadData()
    },
    
    methods:{
      loadData(){
        this.$axios.get(this.$urls.doctor.meeting).then(res=>{
          this.data  = res.data
          this.meetInfo = {
            ready_count:res.ready_count,
            finish_count:res.finish_count
          }
          this.$refs.ItemScroll.initScroll()
        })
      },
      getMeetList(status){
        let list = this.data.filter(item=>status.includes(item.status));
        return list;
      },
      getMeetDate(meetDate){
        let time = this.$tools.getDate(meetDate,"hh:mm");
        let date = this.$tools.getDate(meetDate,"yyyy/MM/dd");
        return `${time}\n${date}`
      },
      selectCourseware(data){
        this.$router.push({
            path:"/coursewares",
            query:{
              type:1,
              meetId:data.id,
              select:1
            },
          })
      },
      previewCourseware(data){
          this.$router.push({
                path:'/preview',
                query:{
                  select:0,
                  type:1,
                  coursewareId:data.courseware_id
                }
            })
        },
      inMeet(item){
          this.$router.push({
            path:item.meeting_url.split('#')[1],
            query:{
              role:1,
            }
          })　
      },
      getCount(data){
        this.meetInfo = {...data}
      },
      inMeetDetail(item){
          this.$router.push({ path: '/meetDetail', query: { meetId:item.id } })　
      },
      openBook(){
          this.$router.push({
              path:'/notebook',
              query:{
                  type:1
              },
          })
      }
    },
    components:{
      ItemScroll,
      CustomButton,
    }

}
</script>

<style>
.meetlist{
  font-size:4vw;
  display: flex;
  flex-direction: column;
  height:100%;
  padding:4vw 5.6vw;
}
.meetlist .authname{
  height:14.4vw;
  background:#ececec;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size:3.6vw;
  line-height: 5vw;
}
.meetlist .authname div:first-child{
  color:#000;
  font-size:3.6vw;
  font-weight: 700;
}
.meetlist .authname div:last-child{
  color:#999;
  font-size:3.2vw;
}
.meetlist .meetshareinfo{
  position: absolute;
  width: 42.66vw;
  height: 26.66vw;
  top:-28.4vw;
  left:-13.33vw;
  height:24.8vw;
  background:#ececec;
  border-radius:2vw;
  padding: 3.2vw 6.4vw;
  display: flex;
  flex-direction: column;
  font-size:3.6vw;
  font-weight: 500;
  z-index:1;
}
.meetlist .meetshareInfoBg{
  position: absolute;
  left:0;
  top:0;
  height:100%;
  width:100%;
}
.meetlist .meetshareinfo:after{
  content:'';
  position: absolute;
  left: calc(50% - 4vw);
  bottom:-7vw;
  display: inline-block;
  border-width:4vw;
  border-style: solid;
  border-top-color: #ececec;
  border-right-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.meetlist .meetshareinfo div:first-child{
  border-bottom:1px solid #ddd;
}
.meetlist .meetshareinfo div{
  flex-grow: 1;
  display: flex;
  align-items: center;
}
.meetlist .meetbtngroup{
  flex:12vw 0 0 ;
  display: flex;
  height:12vw;
  font-size:3.2vw;
  align-items: center;
  font-weight: 700;
  border-bottom:2px solid #ddd
}
.meetlist .meetcurbtn{
  flex-grow: 1;
  text-align: center;
  height:100%;    
  display: flex;
  justify-content: center;
}
.meetlist .meetareaname{
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.meetlist .meetbtnactive{
  border-bottom:2px solid #81304B;
  color:#81304B;
  
}
.meetlist .meetlist_main h4{
  flex:11.6vw 0 0;
  line-height:11.6vw;
  border-bottom:1px solid #ddd;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.meetlist .contcontent{
  margin-top:4vw;
  padding:5.6vw 10.1vw 5.6vw 4.26vw;
  background:#f6f6f6;
  border-radius: 4vw;
  position: relative;
}
.meetlist .meetrow{
  min-height:4vw;
  padding:1vw 0;
  display: flex;
  align-items: flex-start;
  position:relative;

}
.meetlist .meetoperashare{
  position: absolute;
  right:0vw;;
  top:-2vw;
}

.meetrowend{
  display: flex;
  align-items: center;
}
.meetlist .meetrow span{
  vertical-align: middle;
}
.meetlist .meetrow span:first-child{
  display: inline-block;
  min-width:18.4vw;
  /* color:#666; */
}
.meetlist .meetrow>span:last-child{
  font-size:3.6vw;
  /* padding:.3vw; */
}
.meetlist .meetrow .meetinmeet{
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width:22.4vw;
  border-width: 1px;
  border-style: solid;
  background:#f3edef;
  text-align: center;
  border-radius:11.2vw;
  font-weight: 500;
  font-size:3.73vw;
  padding: 0 1.5vw 0 4.2vw;
  line-height:6.9vw;
  height:7.6vw;
  /* line-height: 100%; */
  position: relative;
  display: inline-block;
  /* height: 100%; */

}
.meetlist .meetrow .meetinmeet:last-child{
  margin-left:4vw;
  padding:0 2vw;
  background-color: #c3dccd;
}
.meetlist .meetrow .meetinmeet span{
  min-width:0;
  vertical-align: middle;
  font-weight: 500;
}
.meetlist  .meetinmeet i{
  vertical-align: middle;
}
.meetlist  .meetrighticon{
  height:100%;
  width:8vw;
  position: absolute;
  right:0;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#ccc;
  font-size:2.8vw;
}

.meetlist .meetlist_block{
  /* flex:1 0 0; */
  max-height: calc(50% - 11.6vw)
}
.meetlist_block_margin{
  margin-bottom: 4vw;
}
.meetlist .meetstarttable{
  position: relative;
  padding:2.8vw 0;
}
.meetlist .meettablethead{
  font-size:3.6vw;
  display: flex;
  align-items: center;
  padding-right:2vw;
  height:9.2vw;
  color:#999;
  border-bottom:1px solid #ddd;

}
.meetlist .meettwo div {
  width: 100% !important;
}

.meetlist .meettablethead div,.meetlist .meettabletr div{
  flex-grow: 1;
  text-align: center;
  width:25%;
  padding-right:2vw;
}
.meetlist .meettablethead .meettwo,.meetlist .meettabletr .meettwo{
  flex:3 0 0;
}
.meetlist .meettablethead .meetone,.meetlist .meettabletr .meetone{
  flex:2 0 0;
  word-break: break-all;
  text-align: center
}
.meetlist .meettabletr .meettwo>div:last-child{
  color:#999;
}
.meetlist .meettabletbody{
  position: relative;
  padding-right:2vw;
  font-size:3.2vw;
  border-bottom:1px solid #ddd;
}
.meetlist .meetloadingtext{
  width: 100%;
  font-size: 3.2vw;
  text-align: center;
  padding:0 0 4vw;
}

.meetlist .meettabletbody:last-child{
  border:none
}
.meetlist .meettabletr{
  min-height:14.8vw;
  display: flex;
  align-items: center;
  padding:2vw 0;
}
.meetlist  .meetstartrighticon{
  height:100%;
  width:4vw;
  position: absolute;
  right:0;
  top:0;
  display: flex;
  justify-content: center;
  align-items: center;
  color:#ccc;
  font-size:3.2vw;
}
.meetlist .meetapply{
  height:15.4vw;
  padding:2.8vw 4.8vw;
  /* position: absolute;
  bottom:0; */
  width:100%;
}
.meetlist .meetapply button{
  height:9.8vw;
  border-radius:6.4vw;
  width:100%;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size:4vw;
  /* font-weight: 700; */
  margin:none;
  padding:0 !important;
}
.meetlist .meetapply button>span img,.meetlist .meetapply button>span span{
  vertical-align: middle;
}
.meetlist .meetapply button:hover{
  background:#81304B
}
.meetlist .meetapplyicon{
  width:4.26vw;
  height:4.26vw;
}
.meetrow .customButton_container{
  width:12vw;
  height: 7.6vw !important;
  margin-right: 0;
}
.meetrow-courseware{
  display: flex;
  font-size:3.6vw;
}
.meetrow-courseware-block{
  display: flex;
  align-items: center !important;
}
</style>